
	import http from "../../../../common/services/http";
	import { defineComponent, ref, reactive, onMounted, computed } from "vue";

	export default defineComponent({
		setup(props) {
			const headers = {
				name: "Name",
				slug: "Slug",
			};

			const records = ref({
				data: [],
			});

			const record = reactive({
				name: "",
			});

			const slug = computed(() => {
				return record.name
					.toLocaleLowerCase()
					.replace(",", " ")
					.split(" ")
					.join("-");
			});

			const search = ref("");

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);

			function fetchRecords(page = 1) {
				processing.value = true;
				http.get(`/api/varieties?page=${page}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function searchRecords(e: any) {
				processing.value = true;
				http.get(`/api/varieties?s=${search.value}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function createRecord() {
				http.post("/api/varieties", {
					name: record.name,
					slug: slug.value,
				})
					.then((res) => {
						if (res.data.id) {
							created.value = true;

							record.name = "";
						}
					})
					.finally(() => {
						document.getElementById("hideNewForm")?.click();
						fetchRecords();
					});
			}

			function updateRecord(record: any) {
				record._method = 'PUT'
				http.post(`/api/varieties/${record.id}`, record)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchRecords();
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/varieties/${id}`, { _method: "DELETE" }).then((res) => {
					fetchRecords();
				});
			}

			onMounted(() => {
				fetchRecords();
			});

			return {
				headers,
				fetchRecords,
				processing,
				records,
				createRecord,
				created,
				record,
				slug,
				updateRecord,
				updated,
				deleteRecord,
				search,
				searchRecords,
			};
		},
	});
